<template>
  <div class="box">
    <el-card style="height:98%">
      <div class="box-top">
        <el-input
          placeholder="题目id"
          size="mini"
          style="width: 150px; margin-right: 10px"
          v-model="examinationID"
          clearable
        ></el-input>
        <el-select
          v-model="grade.value"
          placeholder="年级"
          size="mini"
          @change="(val) => changeInquire(val, 'grade')"
        >
          <el-option
            v-for="item in gradeData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="subject.value"
          placeholder="学科"
          size="mini"
          @change="(val) => changeInquire(val, 'subject')"
        >
          <el-option
            v-for="item in subjectData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="questionTypes.id"
          placeholder="题型"
          size="mini"
          @change="(val) => changeInquire(val, 'questionTypes')"
        >
          <el-option
            v-for="item in questionTypesData"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="success" size="mini" @click="seeAbout" style="margin-left: 10px">查询</el-button>
        <el-button
          slot="reference"
          type="primary"
          size="mini"
          @click="$router.push({ name: 'Edit', query: { id: 'add' } })"
        >添加</el-button>
      </div>
      <!-- //表格 -->

      <el-main class="box-card">
        <el-table
          border
          :data="tableData"
          style="width: 100%; margin-top: 25px; margin-bottom: 25px"
        >
          <el-table-column label="编号" width="80" align="center">
            <template slot-scope="{ row }">{{ row.id }}</template>
          </el-table-column>
          <el-table-column label="年级" width="80" align="center">
            <template slot-scope="{ row }">{{ row.gradeName }}</template>
          </el-table-column>
          <el-table-column label="学科" width="80" align="center">
            <template slot-scope="{ row }">{{ row.subjectName }}</template>
          </el-table-column>
          <el-table-column label="题型" width="80" align="center">
            <template slot-scope="{ row }">{{ row.questiontypes }}</template>
          </el-table-column>
          <el-table-column label="题目名称" width="600" align="center" :show-overflow-tooltip="true">
            <template slot-scope="{ row }">
              <div v-html="row.topicName"></div>
            </template>
          </el-table-column>
          <el-table-column label="分数" width="80" align="center">
            <template slot-scope="{ row }">{{ row.topicScore }}</template>
          </el-table-column>

          <el-table-column label="难度" width="180" align="center">
            <template slot-scope="{ row }">
              <el-rate
                disabled
                show-score
                v-model="row.topicDegree"
                text-color="#ff9900"
                style="margin-top: 11px"
              ></el-rate>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="180" align="center">
            <template slot-scope="{ row }">{{ row.createTime }}</template>
          </el-table-column>
          <el-table-column label="操作" width="280" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="preview(scope.$index, scope.row)">预览</el-button>
              <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="10"
          :total="total"
          @current-change="changePaging"
          class="pager"
        ></el-pagination>
      </el-main>
    </el-card>
    <!-- 新建编辑题目点击出的弹窗 -->
    <el-dialog
      :title="formLabelAlign.title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="q-item-contain">
        <span v-html="formLabelAlign.name"></span>
        <span v-for="(span_item, span_index) in formLabelAlign.previewData" :key="span_index">
          <span>
            <span v-html="span_item.serial" class="sn"></span>
            <span v-html="span_item.content"></span>
          </span>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "../../../store/titleList/index.js";
import { transformTimestamp } from "../../../components/time";
import {
  examTopicAll,
  examTopicDelete,
  examTopicMessage,
} from "../../../api/api";
export default {
  data () {
    return {
      examinationID: null,
      //年级
      gradeData: this.parameter.grade(),
      //科目
      subjectData: this.parameter.subject(),
      //题型
      questionTypesData: this.parameter.variantSubjectData(),
      dialogVisible: false, //弹窗
      allow_go: true,
      //新建题目表单和编辑表单
      formLabelAlign: {
        title: "预览",
        name: "",
        previewData: [], //预览的数组
      },
    };
  },
  mounted () { },
  created () {
    this.seeAbout();
  },
  methods: {
    init (params) {
      examTopicAll(params).then((res) => {
        res.data.list.map((item) => {
          var grade = this.gradeData.find((fin) => {
            return fin.value == item.grade;
          });
          item["gradeName"] = grade.label;
          var subject = this.subjectData.find((fin) => {
            return fin.value == item.subject;
          });
          item["subjectName"] = subject.label;
          var questiontypes = this.questionTypesData.find((fin) => {
            return fin.value == item.topicType;
          });
          item["questiontypes"] = questiontypes.label;
          item["createTime"] = transformTimestamp(item.createTime);
        });
        store.commit("setTableData", res.data.list);
        store.commit("setTotal", res.data.total);
      });
    },
    //点击查询
    changeInquire (val, name) {
      switch (name) {
        case "grade":
          let gradeName = this.gradeData.find((item) => {
            return item.value == val;
          });
          store.commit("setGrade", {
            value: gradeName.value,
            label: gradeName.label,
          });
          store.commit("setSubject", { value: null, label: null });
          store.commit("setTableData", []);
          store.commit("setPage", 1);
          store.commit("setTotal", 0);
          store.commit("setQuestionTypes", { id: null, name: null });
          this.examinationID = null;
          this.seeAbout();

          break;
        case "subject":
          let subjectName = this.subjectData.find((item) => {
            return item.value == val;
          });
          store.commit("setSubject", {
            value: subjectName.value,
            label: subjectName.label,
          });
          store.commit("setQuestionTypes", { id: null, name: null });
          store.commit("setTableData", []);
          store.commit("setPage", 1);
          store.commit("setTotal", 0);
          this.examinationID = null;
          this.seeAbout();

          break;
        case "questionTypes":
          let questionTypesName = this.questionTypesData.find((item) => {
            return item.value == val;
          });
          store.commit("setQuestionTypes", {
            id: questionTypesName.value,
            name: questionTypesName.label,
          });
          store.commit("setTableData", []);
          store.commit("setPage", 1);
          store.commit("setTotal", 0);
          this.examinationID = null;
          this.seeAbout();
          break;
        default:
          break;
      }
    },
    //查询
    seeAbout () {
      if (this.examinationID == null || this.examinationID == "") {
        this.init({
          id: this.examinationID,
          pageNum: this.page,
          pageSize: 10,
          subject: this.subject.value,
          grade: this.grade.value,
          topicType: this.questionTypes.id,
        });
        return;
      } else {
        store.commit("setGrade", { value: null, label: null });
        store.commit("setSubject", { value: null, label: null });
        store.commit("setQuestionTypes", { id: null, name: null });
        this.init({
          id: this.examinationID,
        });
      }
    },
    //编辑
    handleEdit (index, row) {
      this.$router.push({ name: "Edit", query: { id: row.id } });
    },
    //预览
    preview (index, row) {
      examTopicMessage({ id: row.id }).then((res) => {
        this.formLabelAlign.name = res.data[0].topicName;
        this.formLabelAlign.previewData = res.data[1][0];
        this.dialogVisible = true;
      });
    },
    //删除
    handleDelete (index, row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var id = row.id;
          examTopicDelete({ id: id }).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.seeAbout();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //分页
    changePaging (val) {
      store.commit("setPage", val);
      this.seeAbout();
    },
    //关闭弹窗
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
  },
  computed: {
    total () {
      return store.state.total;
    },
    page () {
      return store.state.page;
    },
    tableData () {
      return store.state.tableData;
    },
    subject () {
      return store.state.subject;
    },
    grade () {
      return store.state.grade;
    },
    questionTypes () {
      return store.state.questionTypes;
    },
    // examinationID() {
    //   return store.state.examinationID;
    // },
  },
};
</script>

<style lang='less' scoped>
@import '../../../style/k12SetQuestion/index.less';
@import '../../../style/mouse.less';
</style>